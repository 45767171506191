import React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const AbilityHead = ({ ability }) => {
  return (
    <>
      <Typography variant="h5" align="center" gutterBottom>
        {ability.title}
      </Typography>
      <Divider variant="middle" />
      <Typography variant="body2" gutterBottom>
        {ability.description}
      </Typography>
    </>
  );
};

export default AbilityHead;
