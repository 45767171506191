import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    position: 'absolute',
    flexDirection: 'column',
    height: '100%',
    transition: 'all 1s ease-in-out',
  },

  cardImg: {
    width: '100%',
    height: '100%',
    transition: 'all 1s ease-in-out',

    [theme.breakpoints.up('md')]: {
      transform: 'scale(1.1)',
    },
  },

  cardHead: {
    transition: 'all 0.5s ease-in-out',
    width: '70%',
    height: 130,
    transform: 'rotate(0deg)',
    background: 'rgba(0, 0, 0, 0.6)',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& p,hr': {
      display: 'none',
    },

    '& h5': {
      color: 'white',
    },
  },

  cardDetail: {
    transition: 'all 1s ease-in-out',
    width: '90%',

    [theme.breakpoints.up('lg')]: {
      '& .MuiTypography-body2': {
        lineHeight: 1.8,
      },
    },

    '& .MuiPaper-root': {
      background: 'rgba(255, 255, 255, 0.8)',
      height: 'calc(100% - 20px)',
    },

    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column',
      '& .MuiTypography-body2': {
        fontSize: 14,
      },
      '& .MuiPaper-root': {
        height: 'auto',
      },
    },
  },

  detailBody: {
    height: 230,
    overflow: 'scroll',
    [theme.breakpoints.down('xs')]: {
      height: 150,
    },
  },

  card: {
    cursor: 'pointer',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    transition: 'all 1s ease-in-out',

    [theme.breakpoints.up('md')]: {
      flex: 1,
      height: '100vh',

      '& $cardHead': {
        height: 300,
      },

      '&:not($cardOpen):not($cardClose):hover': {
        '& $cardHead': {
          background: 'rgba(189, 189, 189, 0.85)',
          height: 330,
        },
        '& h5': {
          color: '#BC0F15',
        },
      },
    },

    '&:not($cardOpen) $cardDetail': {
      position: 'absolute',
      transform: 'translateY(2000px)',
    },

    '&:not($cardOpen):not($cardClose) h5': {
      [theme.breakpoints.up('xl')]: {
        fontSize: '2rem',
      },
      lineHeight: 2,
    },

    [theme.breakpoints.down('sm')]: {
      height: '50vh',
      '&:not(:nth-child(3))': {
        marginBottom: '5px',
      },
    },
  },

  cardRtl: {
    [theme.breakpoints.up('md')]: {
      '&:not(:nth-child(3))': {
        marginLeft: '5px',
      },
    },
  },

  cardLtr: {
    [theme.breakpoints.up('md')]: {
      '&:not(:nth-child(3))': {
        marginRight: '5px',
      },
    },
  },

  cardOpen: {
    [theme.breakpoints.up('md')]: {
      flexGrow: 10,
    },

    [theme.breakpoints.down('sm')]: {
      maxHeight: 900,
      height: '250vh',
    },

    '& $cardImg': {
      filter: 'brightness(70%)',
      transform: 'scale(1)',
    },

    '& $cardHead': {
      marginBottom: 16,
      [theme.breakpoints.up('md')]: {
        top: 50,
      },
      [theme.breakpoints.down('sm')]: {
        top: 10,
      },

      textAlign: 'center',
      width: '80%',
      height: 'auto',
      background: 'rgba(255, 255, 255, 0.8)',
      transform: 'rotate(0deg)',
      '& p,hr': {
        display: 'block',
      },
      '& h5': {
        color: '#5a5a5a',
      },
    },
  },

  cardClose: {
    [theme.breakpoints.up('md')]: {
      '& $cardHead': {
        position: 'absolute',
        width: '100vh',
        height: 'auto',
        transform: 'rotate(-90deg)',
        transformOrigin: 'top right',
        right: 60,
        [theme.breakpoints.down('md')]: {
          right: 58,
        },
        top: 0,
        borderRadius: 0,
      },
    },
  },
}));

export default useStyles;
