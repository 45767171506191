import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Template from '../components/Template';
import Metadata from '../components/Metadata';
import Accordion from '../components/Accordion';
import { graphql } from 'gatsby';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
}));

const Abilities = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Template>
      <Metadata pageTitle={t('Menu.Abilities')} />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        classes={{ root: classes.root }}
      >
        <Accordion />
      </Grid>
    </Template>
  );
};

export default Abilities;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
