import React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const AbilityDetails = ({ ability, detailBodyClass }) => {
  return ability.details.map((detail, key) => (
    <Grid item xs={12} sm={6} key={key}>
      <Paper elevation={0}>
        <Typography variant="h6" align="center" gutterBottom>
          {detail.title}
        </Typography>
        <Divider variant="middle" />
        <div className={detailBodyClass}>
          {typeof detail.body !== 'string' ? (
            <List disablePadding>
              {detail.body.map((item, key) =>
                item ? (
                  <ListItem key={key} dense>
                    <ListItemIcon>
                      <FiberManualRecordRoundedIcon
                        fontSize="small"
                        style={{ color: '#e42229' }}
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="body2">{item}</Typography>
                    </ListItemText>
                  </ListItem>
                ) : null
              )}
            </List>
          ) : (
            <Typography variant="body2" align="center" gutterBottom>
              {detail.body}
            </Typography>
          )}
        </div>
      </Paper>
    </Grid>
  ));
};

export default AbilityDetails;
