import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import AbilityHead from './AbilityHead';
import AbilityDetails from './AbilityDetails';
import useStyles from './styles';
import { EN } from '../../constants';

const Accordion = () => {
  const { allFile } = useStaticQuery(graphql`
    query Abilities {
      allFile(filter: { relativeDirectory: { eq: "abilities" } }) {
        edges {
          node {
            base
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `);

  const { t, i18n } = useTranslation();
  const currentLng = i18n.language;
  const classes = useStyles();
  const Images = allFile.edges;
  const Img1 = Images.filter((img) => img.node.base === 'engineering.jpg');
  const Img2 = Images.filter((img) => img.node.base === 'manufacturing.jpg');
  const Img3 = Images.filter((img) => img.node.base === 'inspection.jpg');

  const abilities = [
    {
      name: 'card1',
      img: Img1,
      imgAlt: 'Product Engineering',
      title: t('Abilities.Engineering.title'),
      description: t('Abilities.Engineering.description'),
      details: [
        {
          title: t('Abilities.Engineering.details.1.title'),
          body: [
            t('Abilities.Engineering.details.1.body.1'),
            t('Abilities.Engineering.details.1.body.2'),
            t('Abilities.Engineering.details.1.body.3'),
            t('Abilities.Engineering.details.1.body.4'),
            t('Abilities.Engineering.details.1.body.5'),
          ],
        },
        {
          title: t('Abilities.Engineering.details.2.title'),
          body: [
            t('Abilities.Engineering.details.2.body.1'),
            t('Abilities.Engineering.details.2.body.2'),
            t('Abilities.Engineering.details.2.body.3'),
            t('Abilities.Engineering.details.2.body.4'),
            t('Abilities.Engineering.details.2.body.5'),
            t('Abilities.Engineering.details.2.body.6'),
            t('Abilities.Engineering.details.2.body.7'),
          ],
        },
      ],
    },
    {
      name: 'card2',
      img: Img2,
      imgAlt: 'Manufacturing',
      title: t('Abilities.Manufacturing.title'),
      description: t('Abilities.Manufacturing.description'),
      details: [
        {
          title: t('Abilities.Manufacturing.details.1.title'),
          body: t('Abilities.Manufacturing.details.1.body'),
        },
        {
          title: t('Abilities.Manufacturing.details.2.title'),
          body: t('Abilities.Manufacturing.details.2.body'),
        },
      ],
    },
    {
      name: 'card3',
      img: Img3,
      imgAlt: 'Inspection',
      title: t('Abilities.Inspection.title'),
      description: t('Abilities.Inspection.description'),
      details: [
        {
          title: t('Abilities.Inspection.details.1.title'),
          body: [
            t('Abilities.Inspection.details.1.body.1'),
            t('Abilities.Inspection.details.1.body.2'),
            t('Abilities.Inspection.details.1.body.3'),
            t('Abilities.Inspection.details.1.body.4'),
            t('Abilities.Inspection.details.1.body.5'),
            t('Abilities.Inspection.details.1.body.6'),
            t('Abilities.Inspection.details.1.body.7'),
            t('Abilities.Inspection.details.1.body.8'),
          ],
        },
        {
          title: t('Abilities.Inspection.details.2.title'),
          body: [
            t('Abilities.Inspection.details.2.body.1'),
            t('Abilities.Inspection.details.2.body.2'),
            t('Abilities.Inspection.details.2.body.3'),
            t('Abilities.Inspection.details.2.body.4'),
            t('Abilities.Inspection.details.2.body.5'),
            t('Abilities.Inspection.details.2.body.6'),
          ],
        },
      ],
    },
  ];

  const cardClass =
    currentLng === EN
      ? `${classes.card} ${classes.cardLtr}`
      : `${classes.card} ${classes.cardRtl}`;
  const cardClassOpen = `${cardClass} ${classes.cardOpen}`;
  const cardClassClose = `${cardClass} ${classes.cardClose}`;
  const [openCard, setOpenCard] = useState(null);

  // open and close the selected accordion
  const onCardClick = (card) => {
    setOpenCard(openCard !== card ? card : null);
  };

  return (
    <>
      {abilities.map((ability) => (
        <Grid
          item
          container
          className={
            openCard
              ? openCard === ability.name
                ? cardClassOpen
                : cardClassClose
              : cardClass
          }
          onClick={() => onCardClick(ability.name)}
          onKeyDown={() => onCardClick(ability.name)}
          role="button"
          key={ability.name}
        >
          {/* Ability Image */}
          <GatsbyImage
            image={ability.img[0].node.childImageSharp.gatsbyImageData}
            alt={ability.imgAlt}
            placeholder="blurred"
            objectFit="cover"
            className={classes.cardImg}
          />

          {/* Ability Content Wrapper */}
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            wrap="nowrap"
            spacing={2}
            className={classes.contentWrapper}
          >
            {/* Ability Header */}
            <Paper elevation={0} className={classes.cardHead}>
              <AbilityHead ability={ability} />
            </Paper>

            {/* Ability Details */}
            {ability.details ? (
              <Grid
                container
                justifyContent="space-between"
                spacing={2}
                className={classes.cardDetail}
              >
                <AbilityDetails
                  ability={ability}
                  detailBodyClass={classes.detailBody}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default Accordion;
